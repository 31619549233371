<template>
  <b-card>
    <b-row>
      <b-col cols="3">
        <h3>{{ confirmItem.id }}</h3>
      </b-col>
      <b-col cols="3">
        <h3>{{ confirmItem.rack }}</h3>
      </b-col>

      <b-col cols="6">
        <h3>Status : {{ confirmItem.stocktaking_status }}</h3>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6">
        <b-row class="border bg-info text-white text-xl-left rounded">
          <b-col cols="2"><b> Durum</b></b-col>
          <b-col cols="2"><b> Tedarikçi</b></b-col>
          <b-col cols="2"><b> Stok/iade</b></b-col>
          <b-col cols="2"><b> Ekleyen</b></b-col>
          <b-col cols="2"><b> Raf/Stok</b></b-col>
          <b-col cols="2"><b>Fiyat</b></b-col>
        </b-row>
        <StocktakingProductOldStock
            v-for="stock in confirmItem.active_old_stocks"
            :key="stock.id"
            :stock="stock"
        />
      </b-col>

      <b-col cols="6">
        <b-row class="border bg-info text-white text-xl-left rounded">
          <b-col cols="2"><b> Durum</b></b-col>
          <b-col cols="2"><b> Tedarikçi</b></b-col>
          <b-col cols="2"><b> Stok/iade</b></b-col>
          <b-col cols="2"><b> Ekleyen</b></b-col>
          <b-col cols="2"><b> Raf/Stok</b></b-col>
          <b-col cols="2"><b>Fiyat</b></b-col>
        </b-row>
        <StocktakingProductOldStock
            v-for="stock in confirmItem.active_new_stocks"
            :key="stock.id"
            :stock="stock"
            :isVisible="true"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import StocktakingProductOldStock from "@/views/v2/stock/stocktaking/StocktakingProductOldStock.vue";

export default {
  name: "ConfirmItem",
  components: {StocktakingProductOldStock},
  props: {
    confirmItem: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>