import { render, staticRenderFns } from "./StocktakingConfirmHome.vue?vue&type=template&id=fcddf0a6&scoped=true&"
import script from "./StocktakingConfirmHome.vue?vue&type=script&lang=js&"
export * from "./StocktakingConfirmHome.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fcddf0a6",
  null
  
)

export default component.exports