<template>
  <b-card>
    <b-overlay :show="show" rounded="sm">
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="11"></b-col>
          <b-col cols="1">
            <b-button-group size="sm">
              <b-button variant="primary" @click="getConfirmList">Getir</b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </b-card>
</template>

<script>
export default {
  name: "Search",
  data: () => ({
    show: false,
  }),
  methods: {
    getConfirmList() {
      this.$store.dispatch('stocktakingConfirm/getConfirmList');
    }
  }
}
</script>

<style scoped>

</style>