<template>
  <section>
    <Search/>
    <ConfirmItem
        v-for="confirmItem in _confirmList"
        :key="confirmItem.id"
        :confirmItem="confirmItem"
    />
  </section>
</template>

<script>

import {mapGetters} from "vuex";
import Search from "@/views/v2/stock/stoctaking-confirm/Search.vue";
import ConfirmItem from "@/views/v2/stock/stoctaking-confirm/ConfirmItem.vue";

export default {
  name: "StocktakingConfirmHome",
  components: {Search, ConfirmItem},
  computed: {
    ...mapGetters('stocktakingConfirm', ['_confirmList']),
  }
}
</script>

<style scoped>

</style>